import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Noise } from 'noisejs';

const ASCIIArtGenerator = () => {
  // Existing state declarations remain unchanged
  const [gridDimensions, setGridDimensions] = useState({ width: 40, height: 30 });
  const [time, setTime] = useState(0);
  const [params, setParams] = useState({
    mandelbrotWeight: 0.0,
    perlinWeight: 0.0,
    quantumWeight: 0.0,
    frequency: 0.1,
    turbulence: 0,
    timeScale: 0.1
  });
  const [messagePhase, setMessagePhase] = useState('pattern');
  const [transitionFrame, setTransitionFrame] = useState(0);
  const noise = useMemo(() => new Noise(Math.random()), []);
  const [targetParams, setTargetParams] = useState({
    mandelbrotWeight: 0.3,
    perlinWeight: 0.4,
    quantumWeight: 0.4
  });
  const transitionStartTime = useRef(null);
  const isTransitioning = useRef(false);

  // **New Function: Generate Skewed Random Values**
  const generateSkewedValues = () => {
    const influences = ['mandelbrotWeight', 'perlinWeight', 'quantumWeight'];
    // Step 1: Randomly pick one influence to be dominant
    const dominantIndex = Math.floor(Math.random() * 3);
    const dominant = influences[dominantIndex];
    // Assign it a value between 0.6 and 1
    const dominantValue = 0.6 + Math.random() * 0.4; // 0.6 to 1

    // Step 2: Assign the other two influences values summing to <= 0.5
    const remainingInfluences = influences.filter((_, i) => i !== dominantIndex);
    // Generate first value between 0 and 0.5
    const firstValue = Math.random() * 0.5;
    // Generate second value between 0 and (0.5 - firstValue)
    const secondValue = Math.random() * (0.5 - firstValue);

    // Create the new target object
    return {
      [dominant]: dominantValue,
      [remainingInfluences[0]]: firstValue,
      [remainingInfluences[1]]: secondValue
    };
  };

  // Existing useEffect hooks for grid size, animation, and message cycle remain unchanged
  useEffect(() => {
    const updateSize = () => {
      const headerHeight = 200;
      const cellSize = 20;
      const availableWidth = window.innerWidth - 64;
      const availableHeight = window.innerHeight - headerHeight - 64;
      const width = Math.max(40, Math.floor(availableWidth / cellSize));
      const height = Math.max(30, Math.floor(availableHeight / cellSize));
      setGridDimensions({ width, height });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime((t) => t + 1), 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const cycleMessage = () => {
      setMessagePhase('aaron');
      setTimeout(() => {
        setMessagePhase('transition');
        let frame = 0;
        const transitionInterval = setInterval(() => {
          frame++;
          setTransitionFrame(frame);
          if (frame >= 5) {
            clearInterval(transitionInterval);
            setMessagePhase('patel');
            setTimeout(() => setMessagePhase('pattern'), 1000);
          }
        }, 100);
      }, 1000);
    };
    cycleMessage();
    const interval = setInterval(cycleMessage, 10000);
    return () => clearInterval(interval);
  }, []);

  // Existing combinedFormula and rendering logic remain unchanged
  const combinedFormula = (col, row, t, p) => {
    const { width, height } = gridDimensions;
    const x = col;
    const y = row;
    const flowX = x + Math.sin(t * p.timeScale * 0.2 + y * 0.1) * 2;
    const flowY = y + Math.cos(t * p.timeScale * 0.2 + x * 0.1) * 2;
    const scaleX = flowX / (width * 0.25);
    const scaleY = flowY / (height * 0.25);
    const scaleT = t * 0.05;

    const wave1 = Math.sin(scaleX * 5 * p.frequency + scaleT + Math.sin(scaleT * 0.5) * 2) * 
                  Math.cos(scaleY * 5 * p.frequency + Math.cos(scaleT * 0.5) * 2);
    const wave2 = Math.sin((scaleX + scaleY) * 4 * p.frequency + Math.sin(scaleT * 0.3) * 3);
    const sinePattern = wave1 + wave2;

    const mandelbrotPattern = (() => {
      if (p.mandelbrotWeight === 0) return 0;
      const centerX = Math.sin(scaleT * 0.3) * width * 0.2;
      const centerY = Math.cos(scaleT * 0.3) * height * 0.2;
      let zx = (flowX - width / 2 + centerX) / (width / 3);
      let zy = (flowY - height / 2 + centerY) / (height / 3);
      let i = 0;
      const maxIter = 50;
      while (zx * zx + zy * zy < 4 && i < maxIter) {
        const tmp = zx * zx - zy * zy + Math.sin(scaleT) * 0.8;
        zy = 2 * zx * zy + Math.cos(scaleT) * 0.8;
        zx = tmp;
        i++;
      }
      return (i / maxIter) * p.mandelbrotWeight;
    })();

    const perlinPattern = (() => {
      if (p.perlinWeight === 0) return 0;
      const nx = scaleX * 4 * p.frequency + Math.sin(scaleT);
      const ny = scaleY * 4 * p.frequency + Math.cos(scaleT);
      return (noise.perlin2(nx, ny) * 0.5 + 0.5) * p.perlinWeight;
    })();

    const quantumPattern = (() => {
      if (p.quantumWeight === 0) return 0;
      const phase = Math.sin(scaleT * 2) * Math.PI;
      const psi = Math.sin(scaleX * 6 * p.frequency + phase) * 
                  Math.sin(scaleY * 6 * p.frequency + phase) * 
                  Math.cos(scaleT * p.timeScale);
      return (psi * psi) * p.quantumWeight;
    })();

    const turbulence = Math.sin(
      scaleX * scaleY * p.turbulence * 4 + 
      Math.sin(scaleT * 2) * 2 + 
      Math.cos(scaleX * 0.5) * Math.sin(scaleY * 0.5) * 4
    ) * 0.5;

    const combined = sinePattern + mandelbrotPattern + perlinPattern + quantumPattern + turbulence;
    return Math.min(1, Math.max(0, (combined + 2) / 4));
  };

  const characterSet = ' .\'`^":;Il!i><~+_-?][}{1)(|\\/◇tfjrxnuvcz□X◊YUJCLQ0OZmwqpdbk◆hao◉▣■*#●░MW&8%B@$▒▓'.split('');
  const getCharacter = (value) => characterSet[Math.floor(value * (characterSet.length - 1))];
  const getRandomLetter = () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ~+_-?][}{1)(|\\/◇tfjrxnuvcz□X◊YUJCLQ0OZmwqpdbk◆hao◉▣■*#●░MW&8%B@$▒▓'[Math.floor(Math.random() * 61)];

  // **Updated Transition Logic**
  useEffect(() => {
    let timeoutId;

    const startTransition = () => {
      // Generate new skewed values
      const newTargets = generateSkewedValues();
      setTargetParams(newTargets);
      transitionStartTime.current = Date.now();
      isTransitioning.current = true;

      // Schedule next transition in 10 seconds
      timeoutId = setTimeout(startTransition, 10000);
    };

    // Start first transition after 8 seconds
    timeoutId = setTimeout(startTransition, 8000);

    return () => clearTimeout(timeoutId);
  }, []);

  // **Tweening Logic (Smooth Transition)**
  useEffect(() => {
    const updateTransition = () => {
      if (!isTransitioning.current) return;

      const now = Date.now();
      const elapsed = now - transitionStartTime.current;
      const duration = 2000; // 2-second transition

      if (elapsed < duration) {
        const progress = elapsed / duration; // 0 to 1
        setParams((prev) => ({
          ...prev,
          mandelbrotWeight: prev.mandelbrotWeight + (targetParams.mandelbrotWeight - prev.mandelbrotWeight) * progress,
          perlinWeight: prev.perlinWeight + (targetParams.perlinWeight - prev.perlinWeight) * progress,
          quantumWeight: prev.quantumWeight + (targetParams.quantumWeight - prev.quantumWeight) * progress
        }));
        requestAnimationFrame(updateTransition);
      } else {
        // Snap to final values
        setParams((prev) => ({
          ...prev,
          mandelbrotWeight: targetParams.mandelbrotWeight,
          perlinWeight: targetParams.perlinWeight,
          quantumWeight: targetParams.quantumWeight
        }));
        isTransitioning.current = false;
      }
    };

    if (isTransitioning.current) requestAnimationFrame(updateTransition);
  }, [targetParams]);

  // Existing canvas rendering and event handlers remain unchanged
  useEffect(() => {
    const canvas = document.getElementById('ascii-canvas');
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const cellSize = 24;
    canvas.width = gridDimensions.width * cellSize;
    canvas.height = gridDimensions.height * cellSize;
    ctx.font = '20px "Courier New", monospace';
    ctx.textBaseline = 'top';

    const generateGrid = () => {
      return Array(gridDimensions.height).fill().map((_, row) =>
        Array(gridDimensions.width).fill().map((_, col) => ({
          value: combinedFormula(col, row, time, params),
        }))
      );
    };

    const cells = generateGrid();
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'white';

    const middleRow = Math.floor(gridDimensions.height / 2);
    const middleStartCol = Math.floor((gridDimensions.width - 5) / 2);

    cells.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        let char;
        if (rowIndex === middleRow && colIndex >= middleStartCol && colIndex < middleStartCol + 5) {
          if (messagePhase === 'aaron') char = 'AARON'[colIndex - middleStartCol];
          else if (messagePhase === 'patel') char = 'PATEL'[colIndex - middleStartCol];
          else if (messagePhase === 'transition') char = getRandomLetter();
          else char = getCharacter(cell.value);
        } else {
          char = getCharacter(cell.value);
        }
        ctx.fillText(char, colIndex * cellSize, rowIndex * cellSize);
      });
    });
  }, [time, params, gridDimensions, messagePhase, transitionFrame]);

  const handleParamChange = (param, value) => {
    setParams((prev) => ({ ...prev, [param]: parseFloat(value) }));
  };

  return (
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    justifyContent: 'center', // Center vertically if needed
    padding: '0', // Remove padding to make it full-screen
    backgroundColor: 'black', 
    minHeight: '100vh', 
    color: 'white' 
  }}>
    {/* Comment out the title and the Pattern Influences panel */}
    {/* <h1 style={{ 
      fontSize: '1.5rem', 
      marginBottom: '24px', 
      color: 'white' 
    }}>Combined Mathematical Patterns</h1> */}
    {/* <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      gap: '16px', 
      width: '100%', 
      maxWidth: '1536px', 
      marginBottom: '32px' 
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '16px', 
        width: '100%' 
      }}>
        <div style={{ 
          flex: 1, 
          backgroundColor: '#111827', 
          padding: '16px', 
          borderRadius: '8px', 
          border: '1px solid #1F2937' 
        }}>
          <h2 style={{ 
            fontSize: '1.125rem', 
            fontWeight: '600', 
            marginBottom: '8px', 
            color: 'white' 
          }}>Pattern Influences</h2>
          <div style={{ 
            display: 'grid', 
            gridTemplateColumns: '1fr', 
            gap: '8px' 
          }}>
            <div>
              <label style={{ display: 'block', fontSize: '0.875rem', color: '#D1D5DB' }}>Mandelbrot Influence</label>
              <input type="range" min="0" max="1" step="0.1" value={params.mandelbrotWeight} onChange={(e) => handleParamChange('mandelbrotWeight', e.target.value)} style={{ width: '100%', accentColor: '#6B7280', backgroundColor: '#374151', height: '8px', borderRadius: '8px', cursor: 'pointer' }} />
            </div>
            <div>
              <label style={{ display: 'block', fontSize: '0.875rem', color: '#D1D5DB' }}>Perlin Noise Influence</label>
              <input type="range" min="0" max="1" step="0.1" value={params.perlinWeight} onChange={(e) => handleParamChange('perlinWeight', e.target.value)} style={{ width: '100%', accentColor: '#6B7280', backgroundColor: '#374151', height: '8px', borderRadius: '8px', cursor: 'pointer' }} />
            </div>
            <div>
              <label style={{ display: 'block', fontSize: '0.875rem', color: '#D1D5DB' }}>Quantum Wave Influence</label>
              <input type="range" min="0" max="1" step="0.1" value={params.quantumWeight} onChange={(e) => handleParamChange('quantumWeight', e.target.value)} style={{ width: '100%', accentColor: '#6B7280', backgroundColor: '#374151', height: '8px', borderRadius: '8px', cursor: 'pointer' }} />
            </div>
          </div>
        </div>
      </div>
    </div> */}
    <canvas id="ascii-canvas" style={{ backgroundColor: 'black', width: '100vw', height: '100vh' }} />
  </div>
);
};

export default ASCIIArtGenerator;